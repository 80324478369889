@use "./scss/variables" as _v;
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@1,700&display=swap");
@import "/node_modules/bootstrap/scss/bootstrap.scss";
@import "/node_modules/bootstrap-icons//font/bootstrap-icons.scss";

$modal-fade-transform: scale(0.8);

body {
  background-color: #1f1f1f;
  color: white;
}

.modal-content {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.2);
}

.wrapper {
}

.logo {
  margin-bottom: 1rem;
  img {
    max-width: 100%;
    width: 250px;
  }
}

fieldset {
  border-radius: 0.25rem;
  border: 2px solid _v.$primary;
  padding: 1rem;
  margin-bottom: 1rem;
  legend {
    font-weight: bold;
  }
}

.form-section {
  font-weight: bold;
  color: #333;
  fieldset {
    legend {
      color: #fff;
    }
  }
}

.register-title {
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: #fff;
}

header {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-items: center;
  text-align: center;
}

.menu {
  font-family: "Josefin Sans", sans-serif;
  font-size: 0.85em;
  text-transform: uppercase;
  font-weight: 300;
  display: flex;
  padding: 0;
  justify-content: center;
  li {
    list-style-type: none;
    margin: 0 1rem;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
}

.main-section-wrapper {
  margin-bottom: 8rem;
}

#tournament {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}
#next {
  animation: fadeIn 30s infinite;
  -webkit-animation: fadeIn 30s;
  -moz-animation: fadeIn 30s;
  -o-animation: fadeIn 30s;
  -ms-animation: fadeIn 30s;
}
label .p-Field{
  color: white !important;
  // z-index: 999
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (min-width: 768px) {
  .logo {
    margin-bottom: 0;
    img {
      max-width: 150px;
    }
  }

  .menu {
    margin-bottom: 0;
  }

  header {
    align-items: center;
    margin-top: 1rem !important;
  }

  .main-section-wrapper {
    margin-top: 8rem;
  }
}

.form-btn-wrappers {
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 0.5rem;
  }
  & > * {
    height: 2.5rem;
    width: 3rem;
    padding: 0 !important;
  }
}

.btn {
  border-radius: 1.5rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 0.5rem 1.5rem;
  letter-spacing: 0.1rem;
  border-width: 2px;
}

.btn-primary {
  background-color: #f91b36;
  color: #fff;
  border: 2px solid #f91b36;
}

.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
  color: #f91b36;
  background-color: #fff;
  border-color: #f91b36;
}
.main-buttons {
  margin-top: 2rem;
}
.title-large {
  font-family: "Kanit", sans-serif;
  font-size: 5rem;
  font-style: italic;
}
.main-text {
  font-weight: 500;
  font-style: italic;
  margin-bottom: 2rem;
}

.cls-btn {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}

.kordell {
  display: flex;
  margin-bottom: 2rem;
  &-img {
    img {
      width: 100%;
      max-width: 100%;
      border-radius: 50%;
    }
  }
  &-content {
    margin-left: 1rem;
    &-title {
      font-family: "Kanit", sans-serif;
      font-size: 2.5rem;
      &-small {
        font-family: "Josefin Sans", sans-serif;
        font-size: 1rem;
        margin-bottom: -1rem;
        font-style: italic;
        opacity: 0.6;
      }
    }
  }
}
@media screen and (max-width: 995px) {
  .tournament {
    font-size: 45px;
  }
  .timer {
    width: 70% !important;
    margin: 0 auto !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 900px) {
}
@media screen and (max-width: 500px) {
  .tournament {
    font-size: 40px !important;
  }
  .kordell {
    display: block !important;
    text-align: center !important;
  }
  .hero {
    text-align: center !important;
  }
  .kordell-img img {
    width: 60% !important;

    margin: 0 20% !important;
  }
  .timer {
    width: 80% !important;
  }
}

