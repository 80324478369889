@use "./scss/variables" as v;
@import url("/node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: v.$black;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4 {
  font-family: "Secular One", sans-serif;
}

.section {
  min-height: 1000px;
}
.select option {
  padding: 10px 2px;
}
